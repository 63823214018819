import React , { useState } from 'react';
import './Footer.css';
import { images, data } from '../../constants';
import { BsFacebook, BsTwitter, BsLinkedin ,BsInstagram,BsYoutube, BsTwitterX} from 'react-icons/bs'

import { useTranslation } from 'react-i18next';

import Dropdown from 'react-bootstrap/Dropdown';
import i18n from '../../components/i18n';
import { useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CarbonCalculator from '../../components/CarbonCalculator';

import { Link } from 'react-router-dom';
const Footer = () => {
  const { t } = useTranslation(); 


  const [language, setLanguage] = useState('en');
  const location = useLocation();
 

  const handleLanguageChange = (selectedLanguage) => {
      setLanguage(selectedLanguage);
      i18n.changeLanguage(selectedLanguage); // Change the language using i18n
  };
  const [subValue, setSubValue] = useState('');

  const handleSubmit = (e) => {
      e.preventDefault();
      setSubValue('');
      alert('Thankyou, you are subscribed to receive our daily newsletter');
  };

  const currYear = new Date().getFullYear();


  return (
<div>
<footer>
      
      
      <Container classname='footer-part-2'>
     
     <Row>
       <Col  xs={12} md={4}>

       <div className="footer-content text-left">
<div className="footer-logo ">
          <img src={images.logofooter} alt="logo" className="img-fluid-footer" />
        </div>
        <div className="foot_subs">
                            <p>Subscribe to our newsletter to receive new products info.</p>
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="email"
                                    className="input_field"
                                    placeholder="Email Address*"
                                    required
                                    value={subValue}
                                    onChange={(e) => setSubValue(e.target.value)}
                                />
                                <button type="submit" className="btn">Subscribe</button>
                            </form>
                        </div>
                    
       
        <div className="language-selection-footer ">
          <Dropdown>
            <Dropdown.Toggle variant="white" id="dropdown-basic" className="dropdown-footer" >
              <img src={images.translate1} alt="Translation Icon" style={{ width: '30px', height: '30px' }} />
              <p style={{ margin: '10px' }}>Select Langauge</p>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>
              <Dropdown.Item onClick={() => handleLanguageChange('hi')}>Hindi</Dropdown.Item>
              <Dropdown.Item onClick={() => handleLanguageChange('mr')}>Marathi</Dropdown.Item>
              <Dropdown.Item onClick={() => handleLanguageChange('kn')}>Kannada</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
</div>
         


      </Col>

       <Col  xs={12} md={3}><ul className="info-contact" style={{fontSize:'16px'}}>
            <li> <span>Contact us</span></li>
           
            <li><span>India Office</span></li>
            <li><b>Orelse Technologies Private Limited</b></li>
            <li>Email: vishal@orelse.ai</li>
            <li>Phone: +91 8484001324</li>
            <li>Address: Kolhapur, Maharashtra, India</li>
            <hr style={{
              background:'black', 
              color: 'white',         
          
                }} />
            <li><span>US office </span> </li>
            <li><b>ORELSE LLC</b></li>
            <li>Email: vishal@orelse.ai</li>
            <li>Phone: +1 (877) 829-9990</li>
            <li>Address: 131 Continental Dr, Suite 305, Newark, Delaware, 19713, United States of America</li>
             
          </ul></Col>
           
       <Col  xs={12} md={2}><ul className="info-contact">
            <li> <span>Company</span></li>
            <li><a href="#" >About</a></li>
            <li><a href="#" >Careers</a></li>
            <li><a href="#" >News</a></li>
           
            
          </ul>
          <ul className="info-contact">
            <li> <span>Resources</span></li>
            <li><a href="/resources#carboncal">Calculators </a></li>            
            <li><a href="/resources#charts" >Customer Stories</a></li>
            <li><a href="/resources#blogs" >Blogs</a></li>

            <li style={{textDecoration:'underline'}}><a href="#" ><span>Book a Demo</span></a></li>
            <li style={{textDecoration:'underline'}}><a href="#" ><span>Feedback</span></a></li>
            <li style={{textDecoration:'underline'}}><a href="#" ><span>Log In</span></a></li>
          </ul>
          </Col>

       <Col  xs={12} md={3}> <ul className="info-contact">
            <li> <span>Solutions</span></li>
            <li><a href="#" >Carbon Mangement</a></li>
            <li><a href="#" >Sustainable Supply Chain</a></li>
            <li><a href="#" >Sustainable Green Finance</a></li>
            <li><a href="https://marketplace.orelse.ai/" >Sustainibility Marketplace</a></li>
            <li><a href="#" >Advisory and Consulting Services</a></li>
            <li><a href="#" >Climate Modeling and Impact</a></li>
            <li><a href="#" >Sustainable Materials</a></li>
            <li><a href="#" >Become a Sustainability Volunteer</a></li>
           
          </ul></Col>
       

         

     </Row>
     


<div className="sub_footer">
<div className="separator"></div>
    <div className="container">
        <div className="sub_footer_wrapper">
        <div className="foot_copyright">
        <p>
                               © Copyright {currYear} | orelse technologies pvt ltd. All Rights Reserved.
                                 | <a href="/">Privacy policy</a>
                            </p>
                </div>
               
                <div className="foot_social" style={{ display:'flex',paddingRight: '20px',textDecoration:'none' }}>
                <Link to="/facebook" className="social-link"><BsFacebook /></Link>
    <Link to="/twitter" className="social-link"><BsTwitter /></Link>
    <Link to="/linkedin" className="social-link"><BsLinkedin /></Link>
    <Link to="/instagram" className="social-link"><BsInstagram /></Link>
    <Link to="/youtube" className="social-link"><BsYoutube /></Link>
                </div>
           
            
        </div>
    </div>
</div>

   </Container>


      </footer>




    
    </div>
  )
}

export default Footer